import { Grid, Typography } from "@mui/material";

const MentionsLegales = () => {
  return (
    <Grid container justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item xs={10}>
        <Typography
          variant="h3"
          color="inherit"
          gutterBottom
          style={{ fontFamily: "Conthrax" }}
        >
          Mentions légales
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Éditeur de site
        </Typography>
        <Typography align="justify">
          https://www.angelia.one édité par Bones
          <br />
          {"14 rue d'Auteuil, 78490 VICQ"}
          <br />
          contact@angelia.one
        </Typography>
        <Typography align="justify" sx={{ mt: 1 }}>
          BonEs est une SAS à capital variable, RCS Versailles 911 866 747 -
          SIRET : 911 866 747 00015 - NAF : 6202B
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          {"Conditions d'utilisation"}
        </Typography>
        <Typography align="justify">
          Le site accessible par l’url suivante : https://www.angelia.one est
          exploité dans le respect de la législation française. L’utilisation de
          ce site est régie par les présentes conditions générales. En utilisant
          le site, vous reconnaissez avoir pris connaissance de ces conditions
          et les avoir acceptées. Celles-ci pourront êtres modifiées à tout
          moment et sans préavis par la société BonEs.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Responsable éditorial
        </Typography>
        <Typography align="justify">
          Antoine BONNIN
          <br />
          antoine@angelia.one
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Limitation de responsabilité
        </Typography>
        <Typography align="justify">
          Les informations contenues sur ce site sont aussi précises que
          possibles et le site est périodiquement remis à jour, mais peut
          toutefois contenir des inexactitudes, des omissions ou des lacunes. Si
          vous constatez une lacune, erreur ou ce qui parait être un
          dysfonctionnement, merci de bien vouloir le signaler en utilisant le
          formulaire de contact ou en nous contactant sur l’adresse e-mail
          suivante : contact@angelia.one, en décrivant le problème de la manière
          la plus précise possible (page posant problème, action déclenchante,
          type d’ordinateur et de navigateur utilisé…).
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Hébergeur
        </Typography>
        <Typography align="justify">
          Ce site est hébergé par Google Cloud Platform
          <br />
          8 rue de Londres 75009 Paris France
          <br />
          https://cloud.google.com
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Conditions de service
        </Typography>
        <Typography align="justify">
          Ce site est proposé en langage HTML5, pour un meilleur confort
          d’utilisation et un graphisme plus agréable, nous vous recommandons de
          recourir à des navigateurs modernes comme Safari, Firefox, Chrome…
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Contactez-nous
        </Typography>
        <Typography align="justify">
          Angelia est à votre disposition pour tous vos commentaires ou
          suggestions. Vous pouvez nous écrire par courrier électronique à :
          contact@angelia.one
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MentionsLegales;
