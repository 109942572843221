import { Grid, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Grid container justifyContent="center" alignItems="stretch" spacing={2}>
      <Grid item xs={10}>
        <Typography
          variant="h3"
          color="inherit"
          gutterBottom
          style={{ fontFamily: "Conthrax" }}
        >
          Politique de confidentialité
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography align="justify">
          Nous respectons votre vie privée et nous nous efforçons de protéger
          les informations personnelles que vous nous fournissez. Nous
          apprécions votre confiance et souhaitons être totalement transparents
          quant au traitement de vos données personnelles.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Objectif
        </Typography>
        <Typography align="justify">
          La présente charte a pour objectif de définir les principes
          applicables à la gestion et à l’utilisation des données à caractère
          personnel, dans le respect du règlement général sur la protection des
          données (RGPD).
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Définitions
        </Typography>
        <Typography align="justify">
          Donnée à caractère personnel : une information concernant une personne
          physique identifiée ou identifiable ;<br />
          Consultation des données : consultation, lecture, transmission,
          diffusion, extraction et stockage des données à caractère personnel ;
          <br />
          Responsable des données : désigne la personne physique ou morale qui
          définit la manière dont les données à caractère personnel sont
          traitées et pourquoi elles le sont ;<br />
          Internaute : désigne la personne physique qui traverse ou utilise les
          sites internet pour obtenir des informations ou accéder à des services
          en ligne ;<br />
          Boite e-mail : Nombre illimité de boite e-mail entretenue par un
          fournisseur de service et dotée d'un numéro unique, pouvant également
          être consultée sur Internet par un internaute.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Données personnelles recueillies
        </Typography>
        <Typography align="justify">
          Nous recueillons et traitons les informations suivantes et leur
          utilisation est limitée aux buts spécifiés :<br />
          • Authentification (nom, adresse électronique, numéro de téléphone)
          <br />• Lutte contre la fraude (activités, adresse IP, journaux de
          connexion)
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Collecte des données
        </Typography>
        <Typography align="justify">
          La collecte de vos données à caractère personnel est nécessaire pour
          répondre à vos besoins et vous fournir le service d’Angelia. Les
          données ne seront en aucun cas collectées à des fins commerciales.
          <br />
          La communication entre notre application web et nos serveurs est
          sécurisée grâce à des technologies de cryptage (certificats SSL).
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Stockage des données personnelles
        </Typography>
        <Typography align="justify">
          Nous prenons des mesures de sécurité raisonnables et nous nous
          assurons que vos informations demeurent confidentielles et sécurisées.
          Vos données sont stockées sur des serveurs sécurisés auxquels seuls un
          nombre limité d'employés habilités peut accéder.
          <br />
          Vos données les plus confidentielles (mot de passe, fichiers) sont
          cryptées de bout en bout via des standards de cryptage militaire
          (AES256, RSA4096) permettant de vous assurer que seuls vous et vos
          bénéficiaires sélectionnées puissent accéder à ces données. Aucun
          autres tiers (que ce soit Angelia ou une autre instance) ne pourra y
          accéder.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Utilisation des données
        </Typography>
        <Typography align="justify">
          Toutes les informations recueillies restent confidentielles et ne sont
          pas partagées, vendues ou transférées à des tiers sans votre
          autorisation ou sauf si la loi l'exige.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Conservation des données
        </Typography>
        <Typography align="justify">
          Les données à caractère personnel selon la loi applicable sont
          conservées pendant une durée raisonnable, mais elles peuvent être
          fournies à votre demande. Nous veillons à ce que les données à
          caractère personnel ne soient pas stockées plus longtemps que
          nécessaire.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Conformité au Règlement Général sur la Protection des Données (RGPD)
        </Typography>
        <Typography align="justify">
          Nous nous engageons à appliquer le RGPD et à respecter les obligations
          qui en découlent. Nous nous assurons que toutes les données sont
          traitées conformément à la législation et à la présente charte.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Utilisation des cookies et traceurs
        </Typography>
        <Typography align="justify">
          Nous utilisons des cookies et des traceurs sur notre site Web afin de
          mieux comprendre comment il est utilisé et de vous offrir une
          expérience plus enrichissante et personnalisée.
          <br />
          Vous avez le droit de refuser ces cookies sans aucun impact sur le
          service rendu.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h6" align="justify" sx={{ mb: 1 }}>
          Mise à jour de cette politique
        </Typography>
        <Typography align="justify">
          Nous nous réservons le droit de modifier cette politique à tout
          moment, et vous encourageons à la consulter régulièrement.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
