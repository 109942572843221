import { createTheme, ThemeOptions } from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#011696",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    h1: { fontFamily: "Conthrax", fontSize: "56" },
    h2: {
      fontSize: "42",
      fontWeight: "500",
      color: "#011696",
      textAlign: "center",
    },
    subtitle1: { fontSize: "25" },
    h6: { fontFamily: "Conthrax", fontSize: "22" },
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: { color: "#ffffff", backgroundColor: "#011696" },
      },
    },
    MuiTypography: { defaultProps: { variantMapping: { subtitle1: "p" } } },
  },
};
const theme = responsiveFontSizes(createTheme(themeOptions));

export default theme;
