import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Backdrop, CircularProgress, Container } from "@mui/material";

import { Footer, NavMenu } from "../react-nav-menu";
import Calendly from "./Calendly";

const Article = lazy(() => import("../views/Articles/Article"));
const ArticlesList = lazy(() => import("../views/Articles/ArticlesList"));
const ArticlesListByCategory = lazy(
  () => import("../views/Articles/ArticlesListByCategory")
);
const Communaute = lazy(() => import("../views/Communaute"));
const Contact = lazy(() => import("../views/Contact"));
const FAQ = lazy(() => import("../views/FAQ"));
const Home = lazy(() => import("../views/Home"));
const Solution = lazy(() => import("../views/Solution"));
const Certificate = lazy(() => import("../views/Certificate"));
const MentionsLegales = lazy(
  () => import("../react-nav-menu/Footer/MentionsLegales")
);
const PrivacyPolicy = lazy(
  () => import("../react-nav-menu/Footer/PrivacyPolicy")
);

const Loading = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const pageList = [
  { link: { pathname: "/solution" }, label: "Notre solution" },
  { link: { pathname: "/certificat" }, label: "Certificat numérique" },
  { link: { pathname: "/contact" }, label: "Nous contacter" },
  { link: { pathname: "/communaute" }, label: "Communauté" },
  // { link: { pathname: "/faq" }, label: "F.A.Q." },
  { link: { pathname: "/articles" }, label: "En découvrir plus" },
];

const App = () => {
  return (
    <>
      <Calendly />
      <NavMenu pageList={pageList} />
      <Container
        maxWidth={false}
        component="main"
        sx={{ paddingY: 2 }}
        disableGutters
      >
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="solution" element={<Solution />} />
            <Route path="certificat" element={<Certificate />} />
            <Route path="contact" element={<Contact />} />
            <Route path="communaute" element={<Communaute />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="articles" element={<ArticlesList />} />
            <Route
              path="articles/:categorySlug"
              element={<ArticlesListByCategory />}
            />
            <Route path="article/:articleSlug" element={<Article />} />
            <Route path="mentions_legales" element={<MentionsLegales />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </Container>
      <Footer pageList={pageList} />
    </>
  );
};

export default App;
