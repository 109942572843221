import { Link as RouterLink } from "react-router-dom";

import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import Logo from "../NavMenu/angelia_white.svg";
import { PageLink, SubPageLink } from "../NavMenu/link";

const Footer = ({ pageList }: { pageList: (PageLink | SubPageLink)[] }) => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "primary.main",
        textAlign: "center",
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          textAlign="center"
          spacing={2}
        >
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={8}>
            <img
              style={{ height: "68px", padding: "0px 5px" }}
              alt="Angelia"
              src={Logo}
            />
            <Typography color="#fff">
              Station F, 5 Parvis Alan Turing, 75013 Paris
            </Typography>
            <MadeBy />
            <BottomFooter />
          </Grid>
          <Grid item xs={12} md={2}>
            <SiteMap pageList={pageList} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const SiteMap = ({ pageList }: { pageList: (PageLink | SubPageLink)[] }) => {
  return (
    <List dense>
      <ListItem component={RouterLink} to={"/"} style={{ color: "#fff" }}>
        <ListItemText
          primary="Accueil"
          sx={{
            textAlign: { xs: "center" },
            marginTop: "0px",
            marginBottom: "0px",
          }}
        />
      </ListItem>
      {pageList.map((page, index) => {
        if (page.link) {
          return (
            <ListItem
              component={RouterLink}
              to={page.link}
              style={{ color: "#fff" }}
              key={index}
            >
              <ListItemText
                primary={page.label}
                sx={{
                  textAlign: { xs: "center" },
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              />
            </ListItem>
          );
        } else {
          return page.pages.map((page, insideIndex) => {
            return (
              <ListItem
                component={RouterLink}
                to={page.link}
                style={{ color: "#fff" }}
                key={`${index}_${insideIndex}`}
              >
                <ListItemText primary={page.label} />
              </ListItem>
            );
          });
        }
      })}
      <ListItem
        component="a"
        href="https://app.angelia.one"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#fff" }}
      >
        <ListItemText
          sx={{
            textAlign: { xs: "center" },
            marginTop: "0px",
            marginBottom: "0px",
          }}
          primary="Coffre-fort"
        />
      </ListItem>
    </List>
  );
};

const MadeBy = () => {
  return (
    <Typography variant="body1" color="secondary">
      Made with <FavoriteIcon sx={{ color: "red" }} /> in France.
    </Typography>
  );
};

const BottomFooter = () => {
  return (
    <Typography variant="body2" color="secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://angelia.one/">
        Angelia.one
      </Link>{" "}
      {new Date().getFullYear()}
      {" | "}
      <Link color="inherit" href="mailto:contact@angelia.one" target="_top">
        contact@angelia.one
      </Link>
      {" | "}
      <Link component={RouterLink} color="inherit" to="/mentions_legales">
        Mentions légales
      </Link>
      {" | "}
      <Link component={RouterLink} color="inherit" to="/privacy">
        Politique de confidentialité
      </Link>
    </Typography>
  );
};

export default Footer;
