import { Box, Button, Container, Grid, Typography } from "@mui/material";

const Calendly = () => {
  return (
    <Box
      sx={{
        bgcolor: "#d1495b",
      }}
    >
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 1 }}
        >
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography
                fontSize={14}
                fontWeight={500}
                textTransform={"uppercase"}
                color="#fff"
                sx={{ whiteSpace: "nowrap" }}
              >
                Besoin d'en savoir plus ?
              </Typography>
            </Box>
          </Grid>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                window.open("https://calendly.com/paul-valet/30min", "_blank");
              }}
            >
              Réserver une démo
            </Button>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Calendly;
